<template>
  <div>
    <ScannedListLayout :title="$t('packetsExpedition.title')" :onClose="finish">
      <ScannedList
        :isEmpty="emptyItems"
        :emptyText="$t('packetsScanned.no-packets')"
      >
        <ScannedListItem
          v-for="(item, index) in items"
          :key="index"
          :success="item.success"
          :scannedValue="item.scanned_value"
          :recipient="item.recipient"
          :status="item.human_result || item.failure_reason"
        >
          <template v-slot:center>
            <p v-if="item.parcel_barcode" class="d-flex align-items-center">
              <package-icon class="package-icon" />
              <strong>{{ parcelCode(item.parcel_barcode) }}</strong>
            </p>
          </template>
        </ScannedListItem>
      </ScannedList>

      <template v-slot:footer-buttons>
        <a
          class="btn btn-primary btn-white"
          @click.prevent="printLabel"
          v-if="!emptyItems && expeditionId"
        >
          {{ $t("prints.scanned.expedition.print") }}
        </a>
      </template>

      <template v-slot:footer-buttons-confirm>
        <a class="btn btn-primary btn-green" @click.prevent="confirmExpedition">
          {{ $t("packetsExpedition.expedite") }}
        </a>
      </template>
    </ScannedListLayout>

    <OverlayConfirm
      v-if="showConfirmOverlay"
      :title="$t('packetsExpedition.confirm.title')"
      :subtitle="$t('packetsExpedition.confirm.subtitle')"
      @confirm="expedite"
      @cancel="showConfirmOverlay = false"
      :confirmText="$t('buttonActions.yes')"
      :cancelText="$t('buttonActions.no')"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { getParams } from "@/mixins/platform-params.js";
import { MOBILE_TYPES } from "@/util/mobile.type";
import PackageIcon from "vue-material-design-icons/PackageVariantClosed.vue";
import OverlayConfirm from "@/components/Overlay/Confirm";
import ScannedList from "@/components/ScannedList/List";
import ScannedListItem from "@/components/ScannedList/Item";
import ScannedListLayout from "@/components/ScannedList/Layout";

export default {
  name: "PacketsExpeditionProcess",
  mixins: [getParams],
  components: {
    PackageIcon,
    OverlayConfirm,
    ScannedList,
    ScannedListItem,
    ScannedListLayout
  },
  data() {
    return {
      expeditionId: this.$route.params.expeditionId,
      showConfirmOverlay: false
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    next();
  },

  async mounted() {
    await this.getExpeditionPackets(this.expeditionId);
  },

  computed: {
    ...mapState("scanned", ["items"]),
    ...mapGetters("scanned", ["emptyItems"])
  },

  methods: {
    ...mapMutations(["clearErrors"]),
    ...mapActions("scanned", [
      "getExpeditionPackets",
      "expediteExpedition",
      "reprintExpeditionList"
    ]),

    parcelCode(parcelBarcode) {
      return parcelBarcode.slice(-4);
    },

    async printLabel() {
      await this.reprintExpeditionList(this.expeditionId);
    },

    finish() {
      if (!this.isMobile) {
        this.$router.replace({
          name: "dashboard",
          query: {
            platform: this.platform,
            device: this.device
          }
        });

        return;
      }

      if (this.platform == MOBILE_TYPES.ANDROID) {
        window.PacketaPPA.backToScan();
      } else if (this.platform == MOBILE_TYPES.IOS) {
        window.webkit.messageHandlers.backToScan.postMessage({});
      }
    },

    confirmExpedition() {
      this.showConfirmOverlay = true;
    },

    async expedite() {
      await this.expediteExpedition(this.expeditionId);

      this.finish();
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  .list {
    margin-top: 30px;
  }
}

footer {
  a:first-child {
    margin-bottom: 10px;
  }
}

.package-icon {
  margin-right: 0.2rem;
}
</style>
